$greyDark: #1D1E22;
$greySlate: #333642;
$greyLight: #666B7A;
$lemonYellow: #FFD33E;

$button-colours: (
    1: #a972ca,
    2: #1ed4d4,
    3: #ff7f84,
    4: #8fc865,
);

body, button {
    color: whitesmoke;
    font-family: Consolas;
    background: $greyDark;
}

#mainWrapper {
    width: 100vw;
    height: 100vh;
    background: $greyDark;
    display: flex;
}

#sideBar, #contentWrapper {
    background: $greySlate;
    margin: .25%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

#sideBar {
    width: 30%;
    min-width: 250px;
    margin-right: .25%;
    margin-left: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;

    #navBox {
        width: 90%;
        height: 50vh;
        min-height: 400px;
    }

    #mediaLinks {
        margin-top: 5%;
        height: 10%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-content: space-evenly;

        @each $type, $colour in $button-colours {
            .Link-#{$type} {
                background: $colour;
            }
        }

        a {
            width: 40%;
            height: 30%;
            color: black;
            font-weight: bolder;
            font-size: 1rem;
            border: none;
            max-width: 150px;
            min-width: 100px;
            max-height: 60px;
            min-height: 40px;
            cursor: pointer;
            transition: .33s;
            display: grid;
            align-items: center;
            text-align: center;
            user-select: none;
            text-decoration: none;
        }


        a:hover {
            color: white;
            font-size: 1.1rem;
            filter: brightness(110%);
        }
    }

    #pageTitle {
        width: 100%;
        margin-top: 10px;
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    #navList {
        list-style: none;
        border-top: 1px solid $greyLight;
        border-bottom: 1px solid $greyLight;
        padding: 5%;
        font-size: 1rem;
        text-align: center;
        
        a {
            text-decoration: none;
            color: whitesmoke
        }
    }
}

#profileWrapper {
    width: 90%;
    display: grid;
    place-items: center;

    p { 
        text-justify: inter-character;
        width: 65%;
        margin-top: 20%;
    }

    img {
        width: 200px;
        height: 200px;
        border: 2px $greyLight solid;
    }
}

#sideFooter {

    p {
        font-size: .75rem;
    }
}

#contentWrapper {
    width: 75%;
    min-width: 600px;
    margin-left: .25%;
    margin-right: 20%;
    display: flex;
    flex-direction: column;

    #mainContent {
        padding: 20px;

        h1 {
            text-decoration: underline;
            padding-bottom: 20px;
        }

        h2 {
            padding-bottom: 10px;
            border-bottom: 1px solid $greyLight;
        }

        a {
            color: $lemonYellow;
        }
    }
}

//small screens
@media only screen and (max-height: 900px) {
    #profileWrapper{
        p {
            margin-top: 10vh;
        }

        img {
            display: none;
        }
    }
}

@media only screen and (max-width: 1400px) {
    #sideBar{
        margin-left: 10px;
    }

    #contentWrapper {
        margin-right: 10px;
        min-width: 330px;
    }
}

//mobile
@media only screen and (max-width: 601px) {

    #mainWrapper {
        height: 100vh;
        width: 100vw;
        flex-direction: column;
    }

    #navList {
        text-align: center;
    }

    #sideBar{
        width: 100vw;
        min-height: 400px;
        margin: 0;
        margin-bottom: 2px;

        #navBox {
            width: 90%;
            min-height: 150px;
        }

        #mediaLinks {
            margin-top: 40px;
            height: 10%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-content: space-evenly;

            a {
                margin: 5px;
            }
        }

        #profileWrapper {
            display: none;
        }

        #sideFooter {
            display: none;
        }
    }

    #contentWrapper {
        display: flex;
        width: 100vw;
        margin: 0;
        margin-top: 5px;
        height: auto;
    }
}